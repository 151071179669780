import { ClientHintCheck } from '#app/utils/client-hints';
import { type Theme } from '#app/utils/theme.server';
import { Links, LiveReload, Meta, Scripts, ScrollRestoration } from '@remix-run/react';
import React from 'react';

function Document({
  children,
  env = {},
  nonce,
  theme = 'light',
}: {
	children: React.ReactNode
	env?: Record<string, string>
	nonce: string
	theme?: Theme
}) {
  // const isMounted = React.useRef(false);
  // React.useEffect(() => {
  //   if(isMounted.current) {
  //     return;
  //   }
  //   isMounted.current = true;
  //   if(env.MODE === 'production') {
  //     const script = document.createElement('script');
  //     script.src = 'https://analytics.bookcovery.com/script.js';
  //     script.defer = true;
  //     script.setAttribute('data-website-id', '1fda8144-0dd3-42f7-9239-c7211187f6d0');
  //     document.body.appendChild(script);
  //   }
  // }, [env.MODE]);

  return (
    <html className={`${theme} h-full overflow-x-hidden`} lang="en">
      <head>
        <ClientHintCheck nonce={nonce} />
        <meta charSet="utf-8" />
        <meta content="width=device-width,initial-scale=1" name="viewport" />
        <Meta />
        <Links />
      </head>
      <body className="bg-background text-foreground h-full overflow-hidden">
        {children}
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`,
          }}
          nonce={nonce}
        />
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
        <LiveReload nonce={nonce} />
      </body>
    </html>
  );
}

export default Document;